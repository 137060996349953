import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import Cookies from 'js-cookie';

const App = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const loginStatus = Cookies.get('isLoggedIn');
    if (loginStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    if (username === 'byxadmin' && password === 'Ke84gr6!@') {
      Cookies.set('isLoggedIn', 'true', { expires: 7 });
      setIsLoggedIn(true);
      setErrorMessage('');
    } else {
      setErrorMessage('Invalid username or password.');
    }
  };

  const handleLogout = () => {
    Cookies.remove('isLoggedIn');
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  const fetchWebhooks = async () => {
    try {
      const response = await axios.get('http://89.203.250.21/get-webhooks');
      setWebhooks(response.data);
    } catch (error) {
      setErrorMessage('Failed to fetch webhooks.');
      console.error(error);
    }
  };

  const createWebhook = async () => {
    try {
      if (!apiKey || !apiSecret || !name) {
        setErrorMessage('Please fill in all fields.');
        return;
      }
      const response = await axios.post('http://89.203.250.21/create-webhook', {
        apiKey,
        apiSecret,
        name,
      });
      setWebhooks([...webhooks, { name, webhookUrl: response.data.webhookUrl }]);
      setApiKey('');
      setApiSecret('');
      setName('');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Failed to create webhook.');
      console.error(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) fetchWebhooks();
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <div className="login-container">
        <h1>Login</h1>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    );
  }

  return (
    <div className="app-container">
      <h1>ByX Panel</h1>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <div className="form-container">
        <input
          type="text"
          placeholder="API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        <input
          type="text"
          placeholder="API Secret"
          value={apiSecret}
          onChange={(e) => setApiSecret(e.target.value)}
        />
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button onClick={createWebhook}>Create Webhook</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>

      <h2>Existing Webhooks</h2>
      <div className="webhook-list">
        {webhooks.length > 0 ? (
          webhooks.map((webhook, index) => (
            <div key={index} className="webhook-item">
              <p><strong>Name:</strong> {webhook.name}</p>
              <p><strong>URL:</strong> <a href={webhook.webhookUrl} target="_blank" rel="noopener noreferrer">{webhook.webhookUrl}</a></p>
            </div>
          ))
        ) : (
          <p>No webhooks available.</p>
        )}
      </div>
    </div>
  );
};

export default App;
